import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
import { MetaSwiper, SafariSwiper, countUpArr } from "./meta-settings.js";

const header = document.getElementsByClassName(`header`)[0];

// Header scroll
const scrollContainer = () => {
    return document.documentElement || document.body;
};

document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 200) {
        header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
        header.classList.remove("scrolled");
    }
});

// menu handlers

///check mobile menu show/hide condition

const mobileMenuStartPoint = +getComputedStyle(
    document.documentElement
).getPropertyValue("--mobile-menu-start-point");

let isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
    if (isMobileMenuEnable) {
        //close all opened sub menu

        $(".menu-item.dropdown.active .dropdown-menu").slideUp({
            complete: function () {
                $(this).closest(".dropdown").removeClass("active");
            },
        });

        //open current submenu

        $(this).closest(".menu-item.dropdown").toggleClass("active");

        if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
            e.preventDefault();

            $(this).next(".dropdown-menu").slideDown();
        }
    }
});

// toggle menu handler

function menuToggle() {
    $(".menu-toggle").toggleClass("active");
    $(".navbar-nav").toggleClass("active");
    $(".header-close-wrapper").toggleClass("active");

    // LockScroll when burger open and enable when closed and enable scroll on menu
    scrollLock.getScrollState()
        ? scrollLock.disablePageScroll(
              document.querySelector(".navbar-nav .menu")
          )
        : scrollLock.enablePageScroll();
}

//menu update function

function updateMenu() {
    isMobileMenuEnable =
        $(window).outerWidth() <= mobileMenuStartPoint ||
        $(".mobile-header").length;

    if (!isMobileMenuEnable) {
        $(".dropdown-menu").css("display", "");
        $(".header-close-wrapper").removeClass("active");
        $(".menu-item.active").removeClass("active");
        $(".navbar-nav").removeClass("active");
        $(".menu-toggle").removeClass("active");
        // LockScroll when burger open and enable when closed
        scrollLock.enablePageScroll();
    }
}

// for close menu after click anchor link
const menuLinks = document.querySelectorAll(".menu .menu-link");

const anchorMenuLinks = [...menuLinks].filter((link) => link.hash);

anchorMenuLinks.forEach((link) => {
    link.addEventListener("click", () => {
        $(".dropdown-menu").css("display", "");
        $(".header-close-wrapper").removeClass("active");
        $(".menu-item.active").removeClass("active");
        $(".navbar-nav").removeClass("active");
        $(".menu-toggle").removeClass("active");
        // LockScroll when burger open and enable when closed
        scrollLock.enablePageScroll();
    });
});

// for fix bg zoom on mobile
if (document.getElementsByClassName("forest-section")[0]) {
    const forestSectionCont = document.getElementsByClassName(
        "forest-section__cont"
    )[0];

    if (
        (forestSectionCont &&
            navigator.platform.match(/(iPad|iPhone|iPod|Android|Silk)/gi)) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0)
    ) {
        forestSectionCont.classList.remove("fixed-bg");
    }
}

// dynamic vh for mobile browsers
const setVH = () =>
    document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
    );

setVH();

window.addEventListener("resize", setVH);

// Custom Select

const select2Array = Array.from(
    document.getElementsByClassName("select2-item")
);

if (select2Array.length) {
    select2Array.forEach((elem) => {
        $(elem)
            .val(null)
            .select2({
                placeholder: elem.getAttribute("data-placeholder"),
                allowClear: true,
                minimumResultsForSearch: Infinity,
                selectionCssClass: "select2-item",
            });
    });
}

$(window).on("resize orientationchange", updateMenu);

// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

//animate on scroll

function isInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
}

const underlines = document.querySelectorAll(".underline-green");

underlines.forEach((underline) => {
    if (isInViewport(underline) && !underline.classList.contains("active")) {
        underline.classList.add("active");
    }
});

document.addEventListener("scroll", function () {
    underlines.forEach((underline) => {
        if (
            isInViewport(underline) &&
            !underline.classList.contains("active")
        ) {
            underline.classList.add("active");
        }
    });
});

// const counts = Array.from(document.getElementsByClassName("count-up"));
//
// if (counts) {
//     const options = { enableScrollSpy: true };
//
//     let idNumber = 1;
//
//     counts.forEach((count) => {
//         const id = `count-up-${idNumber}`,
//             value = parseInt(count.innerHTML);
//         count.id = id;
//         let symbol = count.innerHTML.replace(/[0-9]/g, "");
//         $("#symbol" + idNumber).html(symbol);
//         idNumber++;
//     });
// }

// const count = document.querySelector('.statistic-list')
// console.log(count)
// if (count) {
//     console.log(count)
//     console.log(countUpArr)
//     const blockTop = $('.statistic-section').offset().top;
//     let CountUpFlag = 0;
//     const $window = $(window);
//     $window.on('load scroll', function() {
//         var top = $window.scrollTop();
//         var height = $window.height();
//         if (top + height >= blockTop) {
//             countUpArr.forEach((num) => {
//                 num.start();
//             });
//             CountUpFlag = 1;
//         }
//     });
// }

//animate on scroll end

const productsSlider = document.getElementsByClassName("products-slider")[0];

if (productsSlider) {
    const sayInitSwiperBreakpoint = productsSlider.dataset.initSwiperOn;

    const saySwiperOptions = {
        observer: true,
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
            el: ".products-slider .swiper-pagination",
            type: "fraction",
            formatFractionCurrent: function (number) {
                return number < 10 ? "0" + number : number;
            },
            formatFractionTotal: function (number) {
                return number < 10 ? "0" + number : number;
            },
        },
        navigation: {
            nextEl: ".products-slider__next",
            prevEl: ".products-slider__prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            550: {
                slidesPerView: 2,
            },
        },
    };

    // init swiper on mobile only

    const initSwiperOnMobile = (
        swiperBlock,
        swiperOptions,
        swipeBreakpoint
    ) => {
        let breakpoint = window.matchMedia(`(max-width: ${swipeBreakpoint})`),
            swiper;

        const breakpointChecker = () => {
            if (breakpoint.matches === true) {
                return (swiper = new Swiper(swiperBlock, swiperOptions));
            } else {
                if (swiper !== undefined) {
                    return swiper.destroy(true, true);
                }
            }
        };
        breakpoint.addListener(breakpointChecker);
        breakpointChecker();
    };

    // init swiper on mobile only

    initSwiperOnMobile(
        ".products-slider",

        saySwiperOptions,

        sayInitSwiperBreakpoint
    );
}

// langDropdown

const langDropdown = document.getElementsByClassName("lang-dropdown")[0];

if (langDropdown) {
    const langDropdownBtn =
        langDropdown.getElementsByClassName("lang-dropdown__btn")[0];

    const langDropdownItem = langDropdown.querySelectorAll(
        ".lang-dropdown__item"
    );

    const arrowLangDropdown = langDropdown.querySelector(
        ".icon-arrow-dropdown"
    );

    langDropdownBtn.addEventListener("click", () => {
        langDropdown.classList.add("active");
    });

    arrowLangDropdown.addEventListener("click", () => {
        langDropdown.classList.toggle("active");
    });

    langDropdownItem.forEach((elem, i) => {
        elem.addEventListener("click", () => {
            langDropdownBtn.textContent = elem.textContent;
            langDropdown.classList.remove("active");
        });
    });

    document.addEventListener("click", (e) => {
        let target = e.target;
        if (!target.closest(".lang-dropdown")) {
            langDropdown.classList.remove("active");
        }
    });
}

//Logistics Map

const logisticsMap = document.getElementsByClassName("logistics-map")[0];

if (logisticsMap) {
    const circlesArr = logisticsMap.querySelectorAll(".circle");
    const countryArr = logisticsMap.querySelectorAll(".country-name");
    const countryMapArr = logisticsMap.querySelectorAll(".country-map");

    circlesArr.forEach((elem, i) => {
        elem.addEventListener("mouseenter", () => {
            elem.classList.add("active");
            countryArr[i].classList.add("active");
            countryMapArr[i].classList.add("active");
        });

        elem.addEventListener("mouseleave", () => {
            elem.classList.remove("active");
            countryArr[i].classList.remove("active");
            countryMapArr[i].classList.remove("active");
        });

        elem.addEventListener("click", () => {
            if (logisticsMap.querySelector(".active")) {
                logisticsMap
                    .querySelector(".circle.active")
                    .classList.remove("active");
                logisticsMap
                    .querySelector(".country-name.active")
                    .classList.remove("active");
                logisticsMap
                    .querySelector(".country-map.active")
                    .classList.remove("active");
            }
            elem.classList.add("active");
            countryArr[i].classList.add("active");
            countryMapArr[i].classList.add("active");
        });
    });
}

// details-popup

const detailsPopup = document.getElementsByClassName("details-popup")[0];

if (detailsPopup) {
    const popupClose = detailsPopup.querySelectorAll(".close-details-popup");
    const popupOpen = document.querySelectorAll(".open-details-popup");

    popupClose.forEach((elem) => {
        elem.addEventListener("click", (e) => {
            detailsPopup.classList.remove("active");
        });
    });

    popupOpen.forEach((elem) => {
        elem.addEventListener("click", (e) => {
            e.preventDefault();
            detailsPopup.classList.add("active");
        });
    });
}

const workSliderRef = document.querySelector(".work-slider");

if (workSliderRef) {
    const workSliderArr = [...workSliderRef.querySelectorAll(".swiper-slide")];
    MetaSwiper(".work-slider", {
        speed: 400,
        spaceBetween: 30,
        slidesPerView: 1,
        centeredSlide: true,
        grabCursor: true,
        loop: workSliderArr.length > 4,
        autoplay: {
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-progressbar",
            type: "progressbar",
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                loop: workSliderArr.length > 2,
            },
            769: {
                slidesPerView: "auto",
                loop: workSliderArr.length > 4,
            },
        },
    });
}

// draw line from el to el

function getOffset(el) {
    const rect = el.getBoundingClientRect();
    const left = rect.left + window.pageXOffset;
    const top = rect.top + window.pageYOffset;
    const width = rect.width || el.offsetWidth;
    const height = rect.height || el.offsetHeight;
    const right = left + width;
    const bottom = top + height;
    const mid = top + height / 2;
    const center = left + width / 2;

    return {
        left,
        right,
        top,
        bottom,
        mid,
        center,
    };
}

const drawLine = (props) => {
    const { startingElement, endingElement, thickness, idx } = props;
    const { el: firstElement, x: horizontal1, y: vertical1 } = startingElement;
    const { el: secondElement, x: horizontal2, y: vertical2 } = endingElement;

    const code = () => {
        const off1 = getOffset(firstElement);
        const off2 = getOffset(secondElement);
        const x1 = off1[horizontal1] - 1;
        const y1 = off1[vertical1] + 2;
        const x2 = off2[horizontal2] + 5;
        const y2 = off2[vertical2] - 5;

        // distance
        const length = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

        // center
        const cx = (x1 + x2) / 2 - length / 2;
        const cy = (y1 + y2) / 2 - thickness / 2;

        // angle
        const angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI);
        const line = document.createElement("div");
        const lineInner = document.createElement("div");

        lineInner.className = "line-to-circle";
        lineInner.id = `line-to-circle-${idx}`;
        line.appendChild(lineInner);
        line.style = `
          z-index: -1;
          padding: 0px; 
          margin: 0px; 
          height: ${thickness}px;
          line-height: 1px; 
          position: absolute; 
          left: ${cx}px;
          top: ${cy}px;
          width: ${length}px;
          -moz-transform:rotate(${angle}deg);
          -webkit-transform:rotate(${angle}deg);
          -o-transform:rotate(${angle}deg);
          -ms-transform:rotate(${angle}deg);
          transform:transform:rotate(${angle}deg);
        `;

        document.body.appendChild(line);
    };

    window.addEventListener("DOMContentLoaded", () => {
        code();
    });
};

const updateLine = (props) => {
    const { startingElement, endingElement, thickness, idx } = props;
    const { el: firstElement, x: horizontal1, y: vertical1 } = startingElement;
    const { el: secondElement, x: horizontal2, y: vertical2 } = endingElement;
    const off1 = getOffset(firstElement);
    const off2 = getOffset(secondElement);
    const x1 = off1[horizontal1] - 1;
    const y1 = off1[vertical1] + 2;
    const x2 = off2[horizontal2] + 5;
    const y2 = off2[vertical2] - 5;

    // distance
    const length = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

    // center
    const cx = (x1 + x2) / 2 - length / 2;
    const cy = (y1 + y2) / 2 - thickness / 2;

    // angle
    const angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI);
    const lineInner = document.querySelector(`#line-to-circle-${idx}`);
    const line = lineInner.parentElement;

    line.style = `
        z-index: -1;
        padding: 0px; 
        margin: 0px; 
        height: ${thickness}px;
        line-height: 1px; 
        position: absolute; 
        left: ${cx}px;
        top: ${cy}px;
        width: ${length}px;
        -moz-transform:rotate(${angle}deg);
        -webkit-transform:rotate(${angle}deg);
        -o-transform:rotate(${angle}deg);
        -ms-transform:rotate(${angle}deg);
        transform:transform:rotate(${angle}deg);
      `;
};

// timeline year width

const setTimelineYearWidth = (elArr, minWidth, maxWidth) => {
    const step = Math.round((maxWidth - minWidth) / elArr.length);

    elArr.forEach((el, idx, arr) => {
        const year = el.querySelector(".timeline__year");

        if (idx === 0) {
            year.style = `width: ${minWidth}%;`;
        } else if (idx === arr.length - 1) {
            year.style = `width: ${maxWidth}%;`;
        } else {
            const magrin = step * idx + minWidth;
            year.style = `width: ${magrin}%;`;
        }
    });
};

const timelineItems = document.querySelectorAll(".timeline__item");

if (timelineItems.length) {
    if (window.matchMedia("(min-width: 551px)").matches) {
        setTimelineYearWidth(timelineItems, 17, 38.6);

        timelineItems.forEach((item, idx) => {
            const year = item.querySelector(".timeline__year");
            const point = item.querySelector(".timeline__item-descr-point");

            drawLine({
                startingElement: { el: year, x: "right", y: "bottom" },
                endingElement: { el: point, x: "left", y: "bottom" },
                idx,
                thickness: "3",
            });
        });
    }

    window.addEventListener("resize", () => {
        if (window.matchMedia("(min-width: 551px)").matches) {
            setTimelineYearWidth(timelineItems, 17, 38.6);
            timelineItems.forEach((item, idx) => {
                const year = item.querySelector(".timeline__year");
                const point = item.querySelector(".timeline__item-descr-point");
                updateLine({
                    startingElement: { el: year, x: "right", y: "bottom" },
                    endingElement: { el: point, x: "left", y: "bottom" },
                    idx,
                    thickness: "3",
                });
            });
        }
    });
}

// intersection observer

const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
};

const callback = function (entries, test) {
    entries.forEach((item) => {
        const { target, isIntersecting } = item;
        const lineToCircle = document.getElementById(
            "line-to-circle-" + target.dataset.idx
        );
        if (isIntersecting) {
            target.classList.add("active");
            lineToCircle.classList.add("active");
        } else {
            target.classList.remove("active");
            lineToCircle.classList.remove("active");
        }
    });
};

const observer = new IntersectionObserver(callback, observerOptions);

const items = document.querySelectorAll(".timeline__item");

items.forEach((item) => {
    observer.observe(item);
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 800,
});

window.afterPopupOpen = function afterPopupOpen() {
    const thumbsProductsSliders = document.getElementsByClassName(
        `details-thumbs-slider`
    )[0];

    if (thumbsProductsSliders) {
        const thumbsSwiperBottom = new Swiper(".thumbs-slider-bottom", {
            spaceBetween: 15,
            slidesPerView: 3,
        });

        SafariSwiper(thumbsSwiperBottom);

        const thumbsSwiperTop = new Swiper(".thumbs-slider-top", {
            spaceBetween: 20,
            navigation: {
                nextEl: ".details-thumbs-slider__next",
                prevEl: ".details-thumbs-slider__prev",
            },
            pagination: {
                el: ".details-thumbs-slider .swiper-pagination",
                type: "fraction",
                formatFractionCurrent: function (number) {
                    return number < 10 ? "0" + number : number;
                },
                formatFractionTotal: function (number) {
                    return number < 10 ? "0" + number : number;
                },
            },
            thumbs: {
                swiper: thumbsSwiperBottom,
            },
        });

        SafariSwiper(thumbsSwiperTop);

        thumbsSwiperBottom.slides.forEach((elem, index) => {
            elem.addEventListener("click", (e) => {
                thumbsSwiperTop.slideTo(index);
            });
        });
    }
};
